import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { graphql, Link } from "gatsby";

// Local imports
import Layout from "src/layouts/LayoutWhiteHeader";
import SEO from "src/components/generic/SEO/SEO";
import config from "data/SiteConfig";
import StripPrimaryResourceSignup from "src/components/generic/StripPrimaryResourceSignup/StripPrimaryResourceSignup";
import { getCookieValue } from "src/utils/utils";

// Styles
// import styles from "./index.module.scss";

const stripPrimary = {
	title: "Free templates? Extra resources? In-depth research? Yes please.",
	subTitle: (
		<React.Fragment>
			<p>
				Our resource library is where we put our free Looker Studio
				templates, extra resources & research.
			</p>
			<p>
				<strong>Get your reporting started:</strong> Not everyone is
				ready for (or needs) a full data warehouse. We&apos;ve got some
				free templates to get your reporting.
			</p>
			<p>
				<strong>More indepth research:</strong> We&apos;re starting to
				put out some of our research. You can get quick summaries on the
				blog but all the indepth bits will be in here.
			</p>
		</React.Fragment>
	)
};

const agencyAgreements = [
	{
		subTitle: "Agency owns the data",
		content: (
			<React.Fragment>
				<p>
					Here we&apos;re providing data services directly to an
					agency. We have a per client agreement with the agency and
					all the data lives with the agency.
				</p>
				<p>
					The primary restriction here is we can only offer this (at
					competitive per client rates) for data sources where there
					is low customisation. e.g. search console, ranking & site
					speed data.
				</p>
				<p>
					If individual clients need a lot of custom work e.g. complex
					analytics implementations, logs etc then we tend to use the
					second model.
				</p>
			</React.Fragment>
		),
		image: null
	},
	{
		subTitle: "Customer owns the data",
		content: (
			<React.Fragment>
				<p>
					Here we&rsquo;re managing data in the customers warehouse
					and working with the agency.
				</p>
				<p>
					We do this when there are complex data sources (Logs, custom
					analytics set-ups.) or complex data requirements.
				</p>
				<p>
					In this case we have a contract with the end customer and a
					20% revenue share with the agency.
				</p>
				<p>
					The agency handles the customer relationship and we handle
					the data.
				</p>
				<p>
					<strong>Agencies get:</strong> access to best in class
					reporting and data that they might not have.
				</p>
				<p>
					<strong>Piped Out gets:</strong> Happy customers whose data
					is being used to full effect because the agency is using it
					day to day. Less account management because the agency
					handles it.
				</p>
			</React.Fragment>
		),
		image: null
	}
];

const propTypes = {
	data: PropTypes.shape().isRequired
};

const Index = ({ data }) => {
	useEffect(() => {
		const cookieValue = getCookieValue("resource_library");
		if (cookieValue === "true") {
			window.location.href = "/resource-library/hub/";
		}
	}, []);

	// Set images
	agencyAgreements[1].image = data.agency1.childImageSharp.fluid;
	agencyAgreements[0].image = data.agency2.childImageSharp.fluid;

	// Set-up tab content.

	const title = "Some really good resources";
	const description =
		"You've got strategies to make, actions to change on your clients site, all while juggling communications and getting ready for the quarterly review. You don't have time to spend debugging a data issue for 4 hours.";
	return (
		<Layout>
			<Helmet title={config.siteTitle} />
			<SEO pageTitle={title} pageDescription={description} />
			<StripPrimaryResourceSignup
				title={stripPrimary.title}
				subTitle={stripPrimary.subTitle}
				fullSized={true}
				isWhite={true}
			/>
		</Layout>
	);
};

Index.propTypes = propTypes;

export default Index;

// The graphql query made here will automatically be entered as the
// prop data in data
export const pageQuery = graphql`
	query ResourceLibraryQuery {
		# This gets the main image
		primaryImage: file(
			relativePath: {
				eq: "images/pages/homepage/homepage_stripprimary.jpg"
			}
		) {
			childImageSharp {
				fluid(maxWidth: 935, maxHeight: 575) {
					...GatsbyImageSharpFluid
				}
			}
		}
		# Get us the main alternating images
		agency1: file(
			relativePath: { eq: "images/pages/use_cases/agency1.jpg" }
		) {
			childImageSharp {
				fluid(maxWidth: 820, maxHeight: 762) {
					...GatsbyImageSharpFluid
				}
			}
		}
		agency2: file(
			relativePath: { eq: "images/pages/use_cases/agency2.jpg" }
		) {
			childImageSharp {
				fluid(maxWidth: 820, maxHeight: 762) {
					...GatsbyImageSharpFluid
				}
			}
		}
		multipleSubfolders: file(
			relativePath: {
				eq: "images/pages/how_does_it_work/multiple_subfolders.jpg"
			}
		) {
			childImageSharp {
				fluid(maxWidth: 431, maxHeight: 570) {
					...GatsbyImageSharpFluid
				}
			}
		}
		reports: file(
			relativePath: { eq: "images/pages/use_cases/reports.jpg" }
		) {
			childImageSharp {
				fluid(maxWidth: 920, maxHeight: 600) {
					...GatsbyImageSharpFluid
				}
			}
		}
		bigquerySize: file(
			relativePath: { eq: "images/pages/homepage/bigquery_size.jpg" }
		) {
			childImageSharp {
				fluid(maxWidth: 482, maxHeight: 247) {
					...GatsbyImageSharpFluid
				}
			}
		}
		dataDiagram: file(
			relativePath: { eq: "images/pages/data-diagram.jpg" }
		) {
			childImageSharp {
				fluid(maxWidth: 933, maxHeight: 658) {
					...GatsbyImageSharpFluid
				}
			}
		}
	}
`;
