import React, { useEffect, useState } from "react";
import styles from "./ActiveCampaignFormCustom.module.scss";

const ActiveCampaignFormCustom = postSubmitSelector => {
	useEffect(() => {
		const script = document.createElement("script");

		script.type = "text/javascript";
		script.src = "/activecampaign_script.js";
		script.async = true;

		document.body.appendChild(script);

		return () => {
			document.body.removeChild(script);
		};
	}, []);

	const [hasAgreedToPrivacyPolicy, setHasAgreedToPrivacyPolicy] = useState(
		false
	);

	return (
		<>
			<form
				method="POST"
				action="https://pipedout.activehosted.com/proc.php"
				id="_form_3_"
				className={`_form _form_3 _inline-form  _dark ${styles.formContainer} activeCampaignInline`}
				noValidate
				data-styles-version="5"
				data-target="hub"
				// data-post-submit={postSubmitSelector}
			>
				<input type="hidden" name="u" value="3" />
				<input type="hidden" name="f" value="3" />
				<input type="hidden" name="s" />
				<input type="hidden" name="c" value="0" />
				<input type="hidden" name="m" value="0" />
				<input type="hidden" name="act" value="sub" />
				<input type="hidden" name="v" value="2" />
				<input
					type="hidden"
					name="or"
					value="9d370fe3a76554b36a93c4c906b5a959"
				/>
				<div className="_form-content">
					<div className="_form_element _x37171501 _full_width">
						<label htmlFor="fullname" className="_form-label">
							Full Name*
						</label>
						<div className="_field-wrapper">
							<input
								type="text"
								id="fullname"
								name="fullname"
								placeholder="John Smith"
								required
							/>
						</div>
					</div>
					<div className="_form_element _x55374505 _full_width m-t-md">
						<label htmlFor="email" className="_form-label">
							Email*
						</label>
						<div className="_field-wrapper">
							<input
								type="text"
								id="email"
								name="email"
								placeholder="john@company.com"
								required
							/>
						</div>
					</div>
					<div className="_form_element _x65313394 _full_width m-t-md">
						<label htmlFor="field[2]" className="_form-label">
							Company*
						</label>
						<div className="_field-wrapper">
							<input
								type="text"
								id="field[2]"
								name="field[2]"
								placeholder="Company Name"
								required
							/>
						</div>
					</div>
					<div className="_form_element _x11332042 _full_width m-t-md">
						<label htmlFor="field[3]" className="_form-label">
							Job Role*
						</label>
						<div className="_field-wrapper">
							<input
								type="text"
								id="field[3]"
								name="field[3]"
								placeholder="Consultant"
								required
							/>
						</div>
					</div>

					<div className="_form_element _x01068138 _full_width m-t-md">
						<fieldset className="_form-fieldset">
							<div className="_row">
								<legend
									htmlFor="field[5][]"
									className="_form-label"
								></legend>
							</div>
							<input
								data-autofill="false"
								type="hidden"
								id="field[5][]"
								name="field[5][]"
								value="~|"
							/>
							<div className="_row _checkbox-radio">
								<input
									id="field_5Subscribe to our irregular newsletter and we&#039;ll send you any new templates, tutorials etc."
									type="checkbox"
									name="field[5][]"
									value="Subscribe to our irregular newsletter and we&#039;ll send you any new templates, tutorials etc."
									defaultChecked
								/>
								<span className="m-l-m">
									<label htmlFor="field_5Subscribe to our irregular newsletter and we&#039;ll send you any new templates, tutorials etc.">
										Subscribe to our irregular newsletter
										and we&#039;ll send you any new
										templates, tutorials etc.
									</label>
								</span>
							</div>
						</fieldset>
					</div>
					<div className="_form_element _x50862249 _full_width m-t-md">
						<fieldset className="_form-fieldset">
							<div className="_row">
								<legend
									htmlFor="field[6][]"
									className="_form-label"
								></legend>
							</div>
							<input
								data-autofill="false"
								type="hidden"
								id="field[6][]"
								name="field[6][]"
								value="~|"
							/>
							<div className="_row _checkbox-radio">
								<input
									id="field_6I've read and understood our privacy policy."
									type="checkbox"
									name="field[6][]"
									value="I've read and understood our privacy policy."
									checked={hasAgreedToPrivacyPolicy}
									onChange={e =>
										setHasAgreedToPrivacyPolicy(
											e.target.checked
										)
									}
								/>
								<span className="m-l-m">
									<label htmlFor="field_6I've read and understood our privacy policy.">
										I've read and understood our{" "}
										<a
											href="/privacy-policy/"
											target="_blank"
											rel="noopener noreferrer"
										>
											privacy policy
										</a>
										.
									</label>
								</span>
							</div>
						</fieldset>
					</div>
					<div className="_button-wrapper _full_width m-t-md">
						<button
							id="_form_3_submit"
							className="_submit button buttonSecondary m-r-md"
							type="submit"
							disabled={!hasAgreedToPrivacyPolicy}
						>
							Submit
						</button>
					</div>
					<div className="_clear-element"></div>
				</div>
				<div
					className="_form-thank-you"
					style={{ display: null }}
				></div>
			</form>
		</>
	);
};

export default ActiveCampaignFormCustom;
