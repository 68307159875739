import React from "react";
import PropTypes from "prop-types";

import styles from "src/components/generic/StripPrimary/StripPrimary.module.scss";
import ActiveCampaignFormCustom from "src/components/generic/ActiveCampaignFormCustom/ActiveCampaignFormCustom";

const propTypes = {
	title: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
		.isRequired,
	subTitle: PropTypes.element.isRequired,
	fullSized: PropTypes.bool,
	isWhite: PropTypes.bool
};

const defaultProps = {
	fullSized: true,
	image: null,
	isWhite: false,
	demoButton: false,
	reportingButton: false,
	includeIntroVideo: false
};

// ${styles.whatIDoBlock
function StripPrimaryResourceSignup({ title, subTitle, fullSized, isWhite }) {
	let topPad = "p-t-lg";
	let botPad = "m-b-lg";
	let backgroundBlock = null;

	const primaryBarColour = isWhite ? "" : "primaryBar";

	if (fullSized) {
		backgroundBlock = <div className={`${styles.backgroundRec}`} />;
		topPad = "p-t-xl";
		botPad = "m-b-xl";
	}

	return (
		<section
			className={`hero ${primaryBarColour} ${styles.backgroundHelper} ${topPad} innerPipeBottom`}
		>
			<div className="outerPipeBottom">
				<div
					className={`container maxContainer outerContainer ${botPad}`}
				>
					<div className="columns is-vcentered">
						<div
							className={`column is-two-thirds-desktop is-two-thirds-tablet is-full-mobile`}
						>
							<h1 className="primaryTitle is-size-2">{title}</h1>
							{subTitle}
							<ActiveCampaignFormCustom />
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}

StripPrimaryResourceSignup.propTypes = propTypes;
StripPrimaryResourceSignup.defaultProps = defaultProps;

export default StripPrimaryResourceSignup;
